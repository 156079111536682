<template>
    <button class="like like_btn" :disabled="!checkHasLike()" :class="{'active' : boardData.is_like === true}" @click="runLikeFunc">
        <span>{{returnCount(boardData.wr_good, 999)}}</span>
    </button>
    <!--좋아요 했다면 button에 class "active"추가 -->
</template>

<script>
    // @ is an alias to /src
    import {mapState, mapGetters} from "vuex";
    export default {
        name: 'BoardLikeDefaultLayout',
        mixins: [],
        components: {
        },
        inject: ['runLikeFunc'],
        props: {
            boardData: {
                default: {},
            },
        },
        data() {
            return{

            }
        },
        beforeRouterEnter() {},
        created() {
        },
        mounted() {

        },
        beforeDestroy() {
        },
        destroyed() {},
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            })
        },
        methods:{
            returnCount(count, maxCount) {
                if(maxCount < count) {
                    return maxCount+'+';
                }
                return count
            },
            checkHasLike() {
                if(this.UserInfo.mb_type === 0 ||this.UserInfo.mb_type === 3) {
                    return false;
                }
                return true;
            },
        },
        watch: {

        },
    }
</script>
